<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <span class="headline">
      <span class="highlight">{{ errorCode }}:</span>
      {{ $t('error-notice.headline') }}
    </span>
    <div class="car">
      <img
        :data-src="resolveFile(`images/error-notice/${carType}_car.jpg`)"
        :src="resolveFile(`images/error-notice/${carType}_car.jpg`)"
        :alt="$t('error-notice.headline')"
        loading="lazy"
      />
    </div>
    <p class="text">
      {{ $t('error-notice.content') }}
    </p>

    <Button
      class="homeLink"
      href="/"
      modifier="primary"
      :title="$t('error-notice.to_start')"
    />

    <SearchBar />
  </div>
</template>

<script>
import './images/orange_car.jpg';
import './images/blue_car.jpg';
import Button from 'Components/01-atoms/button/Button.vue';
import SearchBar from 'Components/02-molecules/search-bar/SearchBar.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState } from 'vuex';

export default {
  name: 'ErrorNotice',
  components: {
    Button,
    SearchBar,
  },
  mixins: [globalMixin],
  props: {
    errorCode: {
      type: Number,
      default: 500,
    },
  },
  computed: {
    ...mapState('core', {
      carType: ({ salesChannel }) =>
        salesChannel.theme === 'autoteile24' ? 'blue' : 'orange',
    }),
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.errorNotice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner {
  padding: 10px 20px;
  overflow: hidden;
}

.headline {
  font-weight: bold;
  color: var(--color-nero);
  font-size: var(--font-size-L);
  line-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: var(--space-4);
  text-align: center;
}

.highlight {
  color: var(--color-primary);
}

.text {
  margin-top: var(--space-2);
  color: var(--color-black--primary);
  font-size: var(--font-size-M);
  line-height: 26px;
  max-width: 480px;
  text-align: center;
}

.car {
  img {
    display: block;
    max-width: 510px;
    width: 100%;
    height: auto;
  }
}

.homeLink {
  max-width: 240px;
  margin: var(--space-4) auto 0;
}

.searchBar {
  margin: var(--space-4) auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  max-width: 560px;
  width: 100%;
}

@media #{$_mediaSDown} {
  .headline {
    font-size: var(--font-size-M);
    line-height: 19px;
  }

  .text {
    font-size: var(--font-size-SM);
  }

  .homeLink {
    max-width: none;
    margin: var(--space-4) 0 0;
  }
}
</style>
